import { baseApiUrl } from './helpers';

import { createResponseClass, ModulesDto, UserInfoDto } from '../types/api.dto';

const { REACT_APP_API_URL: apiModules } = process.env;

export const ModulesResponse = createResponseClass(
	ModulesDto,
	`${baseApiUrl('perevershnyk_utils', apiModules)}/modules`
);
export const UserInfoResponse = createResponseClass(
	UserInfoDto,
	`${baseApiUrl('perevershnyk_utils', apiModules)}/getUserInfo`
);

export const RegisterFCMRequest = `${baseApiUrl('perevershnyk_utils', apiModules)}/register-device`;
