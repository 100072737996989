import { FC, useCallback } from 'react';
import { Payload, Plug } from '@temabit/perevershnyk-uikit/dist/Components';

import { NotificationCenter } from './';
import { CriticalError } from '../../components/critical.error';

import { useClsSWRInfinite } from '../../hooks/url.hooks';

import { UserMessagesResponse } from '../../utils/notifications.api';

import { getApiUrl } from '../../types/api.dto';
import { CheckMessageDto } from '../../types/notifications.api.dto';

import './notification.center.provider.scss';

import { headers } from '../../index';

const PAGE_SIZE = 10;

const noMessagesTitle = 'тут відображатимуться отримані повідомлення';

const getParamPartUrl = (skipPages: number) => {
	const url = new URL(getApiUrl(UserMessagesResponse));

	url.searchParams.set('take', `${PAGE_SIZE}`);
	url.searchParams.set('skip', `${skipPages * PAGE_SIZE}`);

	return url.search;
};

export const NotificationCenterProvider: FC = () => {
	const {
		data: userMessagesData,
		setSize: setUserMessagesPageSize,
		error: userMessagesError,
		mutate: userMessageMutate
	} = useClsSWRInfinite(UserMessagesResponse, getParamPartUrl, headers.positions);

	const isAllLoaded = useCallback(() => {
		return userMessagesData ? userMessagesData[userMessagesData.length - 1].length !== PAGE_SIZE : true;
	}, [userMessagesData]);

	const checkNotificationHandler = useCallback(
		async (id: number, pageNumber: number, arrKey: number, checkMessageResponse?: CheckMessageDto) => {
			try {
				if (checkMessageResponse?.success) {
					await userMessageMutate((data) => {
						if (data) {
							const updatedData = [...data];
							updatedData[pageNumber][arrKey].checked = checkMessageResponse.success;

							return updatedData;
						}
						return data;
					}, false);
				}
			} catch (error) {
				console.log('error', error);
			}
		},
		[userMessageMutate]
	);

	const scrollToBottomHandler = useCallback(() => setUserMessagesPageSize((size) => size + 1), [
		setUserMessagesPageSize
	]);

	if (userMessagesError) {
		return <CriticalError>{userMessagesError.userMessage}</CriticalError>;
	}

	if (userMessagesData && !userMessagesData[0].length) {
		return (
			<section className="notification-center">
				<div className="container">
					<div className="box-module-screen box-in-full-screen">
						<Plug type="no-data">{noMessagesTitle}</Plug>
					</div>
				</div>
			</section>
		);
	}

	if (!userMessagesData && !userMessagesError) {
		return <Payload />;
	}

	if (userMessagesData) {
		return (
			<NotificationCenter
				isAllLoaded={isAllLoaded()}
				messages={userMessagesData}
				onCheckNotification={checkNotificationHandler}
				onScrollToBottom={scrollToBottomHandler}
			/>
		);
	}

	return <></>;
};
