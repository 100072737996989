import { FC } from 'react';
import classNames from 'classnames';

import { useAppState, useAppDispatch } from '../../../context/store';
import { setActiveDataId } from '../../../context/actions';

import { PositionsListProps } from './types';

import './positions.list.scss';

export const PositionsList: FC<PositionsListProps> = ({ onPositionSelect }) => {
	const {
		positions,
		activeData: { position: positionId }
	} = useAppState();

	const dispatch = useAppDispatch();

	return (
		<div
			className={classNames('line', 'positions-list', {
				'has-position': positions?.length
			})}
		>
			<ul className="positions">
				{positions && positions.length > 1
					? positions.map((item, i) => (
							<li className="position-item" key={i}>
								<input
									className="trigger"
									type="radio"
									name="positions"
									id={`pos${i}`}
									checked={i === positionId}
									onChange={() => {
										dispatch(setActiveDataId('position', i));
										onPositionSelect();
									}}
								/>
								<label htmlFor={`pos${i}`} className="position-name">
									{item.positionName}
								</label>
							</li>
					  ))
					: ''}
			</ul>
		</div>
	);
};
