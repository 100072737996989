import { useEffect } from 'react';

import { CriticalError } from '../components/critical.error';

import { useAppDispatch } from '../context/store';
import { setShowLoading } from '../context/actions';

import { SWRError } from '../types/api.dto';

export const useBlockRender = (isLoading: boolean, finish: boolean, error?: SWRError) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		if ((finish && !isLoading) || error) {
			dispatch(setShowLoading(false));
		}
	}, [finish, isLoading, error, dispatch]);

	if (error) {
		return <CriticalError>{error.userMessage}</CriticalError>;
	}

	if (isLoading) {
		return <></>;
	}
};
