import { FC, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AsideNav } from '../aside.nav';
import { NotificationBtnProvider } from '../notification.btn/provider';

import { useAppState } from '../../context/store';

import './header.scss';

const notificationCenterTitle = 'центр сповіщень';

export const Header: FC = () => {
	const {
		modules: { list: modules },
		activeData: { moduleCoords }
	} = useAppState();

	const { pathname } = useLocation();

	const [visibleAside, setVisibleAside] = useState<boolean>(false);

	const getTitleActive = useCallback(() => {
		if (moduleCoords) {
			return modules.find((item) => item.coords.join('') === moduleCoords.join(''))?.title;
		}

		switch (pathname) {
			case '/':
				return '';
			case '/notification-center':
				return notificationCenterTitle;
			default:
				return '';
		}
	}, [moduleCoords, modules, pathname]);

	return (
		<header>
			<div className="container">
				<div className="line-head">
					<button className="btn-toggle-nav" onClick={() => setVisibleAside(true)}>
						<span></span>
						<span></span>
						<span></span>
					</button>
					<span className="title title-active-module">{getTitleActive()}</span>
					<NotificationBtnProvider />
				</div>
			</div>
			<AsideNav statusAside={visibleAside} closeAside={() => setVisibleAside(false)} />
		</header>
	);
};
