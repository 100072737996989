import { FC, Fragment, ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { ScheduleWidgetProvider } from './schedule.widget.provider';
import { NewsWidgetProvider } from './news.widget.provider';
import { BirthdaysWidgetProvider } from './birthdays.widget.provider';

import { MainPlug } from '../main.plug';

import { useAppState } from '../../context/store';

import './widgets.scss';

const widgetTitles = {
	schedule: 'мій графік',
	news: 'останні новини',
	birthdays: 'дні народження моїх колег'
};

const moduleBtnTitles = {
	schedule: 'подивитися весь графік',
	news: 'перейти до новин',
	birthdays: 'дивитись усіх'
};

export const Widgets: FC = () => {
	const {
		modules: { list }
	} = useAppState();

	const initialDateRef = useRef<number>(new Date().setHours(0, 0, 0, 0));

	const [widgets, setWidgets] = useState<ReactNode[]>([] as ReactNode[]);

	const operationGroupsMemo = useMemo(() => {
		const allGroups = list.map((item) =>
			item.operationName ? item.operationName.split('.')[0].toLocaleLowerCase() : ''
		);

		return allGroups.filter((item, i, arr) => arr.indexOf(item) === i);
	}, [list]);

	useEffect(() => {
		setWidgets((widgets) => {
			if (!widgets.length) {
				const widgetsArr = [] as ReactNode[];

				for (let i = 0; i < operationGroupsMemo.length; i++) {
					if (operationGroupsMemo[i].includes('schedule')) {
						const coords = list.find((item) => item.operationName?.toLowerCase().includes('.schedule'))?.coords;
						widgetsArr.unshift(
							<ScheduleWidgetProvider
								moduleCoords={coords}
								title={widgetTitles.schedule}
								moduleBtnTitle={moduleBtnTitles.schedule}
								date={initialDateRef.current}
							/>
						);
						continue;
					}

					if (operationGroupsMemo[i].includes('spbridge')) {
						const moduleData = list.find((item) => {
							return item.operationName?.toLowerCase().includes('getnews');
						});

						const coords = moduleData?.coords;

						widgetsArr.unshift(
							<NewsWidgetProvider
								moduleCoords={coords}
								title={widgetTitles.news}
								moduleBtnTitle={moduleBtnTitles.news}
							/>
						);
						continue;
					}

					if (operationGroupsMemo[i].includes('birthdays')) {
						const moduleData = list.find((item) => {
							return item.operationName?.toLowerCase().includes('.birthdays');
						});

						const coords = moduleData?.coords;

						widgetsArr.push(
							<BirthdaysWidgetProvider
								moduleCoords={coords}
								title={widgetTitles.birthdays}
								moduleBtnTitle={moduleBtnTitles.birthdays}
								date={initialDateRef.current}
							/>
						);
						continue;
					}
				}

				return widgetsArr;
			} else {
				return widgets;
			}
		});
	}, [list, operationGroupsMemo]);

	if (!widgets.length) {
		return <MainPlug />;
	}

	return (
		<div className="widgets-block box-in-full-screen">
			{widgets.map((item, i) => (
				<Fragment key={i}>{item}</Fragment>
			))}
		</div>
	);
};
