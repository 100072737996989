import { AxiosServerError } from './types';
import { SWRError } from '../../types/api.dto';

const isSWRError = (error?: SWRError): error is SWRError => {
	return (error as SWRError) !== undefined;
};

const isServerError = (error?: SWRError): error is AxiosServerError => {
	if (!isSWRError(error)) {
		return false;
	}

	return error.response !== undefined;
};

export const isFZCoreError = (error?: SWRError): error is AxiosServerError => {
	if (!isServerError(error)) {
		return false;
	}
	const properResStatuses = [403, 500];
	const isProperResStatus = properResStatuses.indexOf(error.response.status) !== -1;
	if (!isProperResStatus) {
		return false;
	}

	const isStringResponse = typeof error.response.data === 'string';
	if (!isStringResponse) {
		return false;
	}

	return true;
};
