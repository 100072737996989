import { FC, useCallback, useEffect, useRef } from 'react';

import { NotificationCenterLoaderProps } from './types';

import LoaderIcon from '../../assets/images/icons/loader.svg';

const loadingTitle = 'завантаження';

export const NotificationCenterLoader: FC<NotificationCenterLoaderProps> = ({ isAllLoaded, onScrollToBottom }) => {
	const loaderRef = useRef<HTMLDivElement>(null);
	const observerOptsRef = useRef<IntersectionObserverInit>({
		root: null,
		threshold: 0.5
	});

	const handleObserver = useCallback(
		(entities: IntersectionObserverEntry[]) => {
			const target = entities[0];

			if (target.isIntersecting) {
				onScrollToBottom();
			}
		},
		[onScrollToBottom]
	);

	useEffect(() => {
		const observer = new IntersectionObserver(handleObserver, observerOptsRef.current);

		if (loaderRef.current) {
			observer.observe(loaderRef.current);
		}
	}, [handleObserver]);

	if (!isAllLoaded) {
		return (
			<div className="load-more" ref={loaderRef}>
				<div className="load-content">
					<img src={LoaderIcon} className="load-icon" alt="loader" />
					<span className="load-text">{loadingTitle}</span>
				</div>
			</div>
		);
	}

	return <></>;
};
