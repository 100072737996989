import { createContext, Dispatch, FC, Reducer, useContext, useReducer } from 'react';
import { MobileWindow, useShellClient } from '@temabit/perevershnyk-rpc-shell';
import { HttpClient } from '@temabit/perevershnyk-rpc-shell/dist/http/http.client';

import { reducer } from './reducer';

import { State, Action, IFrameParam } from './types';
import { Module } from '../types/api.dto';

const initialState: State = {
	client: {} as HttpClient,
	rpc: ((window as unknown) as MobileWindow).$RPC,
	modules: {
		tree: [] as Module[],
		list: [] as Module[],
		originalTree: [] as Module[]
	},
	positions: null,
	fio: null,
	activeData: {
		moduleCoords: null,
		position: null
	},
	uncheckedMessages: false,
	iFrameParams: [] as IFrameParam[],
	showLoading: true,
	refreshIframe: null
};

const AppStateContext = createContext<State>(initialState);
const AppDispatchContext = createContext<Dispatch<Action>>(() => null);

export const AppProvider: FC = ({ children }) => {
	const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, initialState);
	const client = useShellClient() as HttpClient;

	if (client) {
		return (
			<AppStateContext.Provider value={{ ...state, client }}>
				<AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
			</AppStateContext.Provider>
		);
	}

	return <></>;
};

export const useAppState = (): State => {
	const context = useContext(AppStateContext);

	return context;
};

export const useAppDispatch = (): Dispatch<Action> => {
	const context = useContext(AppDispatchContext);

	return context;
};
