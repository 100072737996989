import { FC } from 'react';
import classNames from 'classnames';

import { ChildListItemProps } from './types';
import { ListItemType } from '../types';

import './child.list.item.scss';

export const ChildListItem: FC<ChildListItemProps> = ({
	idString = null,
	isActive = false,
	listItem = {} as ListItemType,
	onActiveItemSelect,
	onItemSelect
}) => {
	return (
		<li className="child-list-item">
			<input
				className="list-radio"
				type="radio"
				name="tree-list-children"
				id={`child-${idString || listItem.coords?.join('') || 'item'}`}
				checked={isActive}
				onChange={() => {
					onItemSelect && onItemSelect(listItem.coords as number[]);
				}}
				onClick={() => {
					if (isActive) {
						onActiveItemSelect && onActiveItemSelect(listItem.coords as number[]);
					}
				}}
			/>
			<label
				className={classNames('btn', 'list-item-label', {
					active: isActive
				})}
				htmlFor={`child-${idString || listItem.coords?.join('') || 'item'}`}
			>
				<span className="list-item-title">{listItem.title}</span>
			</label>
		</li>
	);
};
