import { Action, ActiveDataPayloadType, IFrameParam } from './types';
import { AppActionTypes } from './action.types';
import { Module, Position } from '../types/api.dto';
import { mappedModulesList } from '../utils/helpers';

const dispatchAction = (type: Action['type'], payload: Action['payload']): Action => {
	return { type, payload } as Action;
};

export const setModules = (modules: Module[], activeStaffId?: number): Action => {
	const separatedModulesData = mappedModulesList(modules, activeStaffId);

	return dispatchAction(AppActionTypes.SetModules, { modules: { ...separatedModulesData } });
};

export const setPositions = (positions: Position[]): Action => {
	return dispatchAction(AppActionTypes.SetPositions, { positions });
};

export const setFio = (fio: string): Action => {
	return dispatchAction(AppActionTypes.SetFio, { fio });
};

export const setActiveDataId = (
	field: ActiveDataPayloadType['field'],
	value: ActiveDataPayloadType['value']
): Action => {
	return dispatchAction(AppActionTypes.SetActiveDataId, { field, value });
};

export const setUncheckedMessages = (uncheckedMessages: boolean): Action => {
	return dispatchAction(AppActionTypes.SetUncheckedMessages, { uncheckedMessages });
};

export const setIFrameParams = (iFrameParams: IFrameParam[]): Action => {
	return dispatchAction(AppActionTypes.SetIFrameParams, { iFrameParams });
};

export const resetIFrameParams = (): Action => {
	return dispatchAction(AppActionTypes.ResetIFrameParams, undefined);
};

export const setShowLoading = (showLoading: boolean): Action => {
	return dispatchAction(AppActionTypes.SetShowLoading, { showLoading });
};

export const setRefreshIframe = (refreshIframe: () => void): Action => {
	return dispatchAction(AppActionTypes.SetRefreshIframe, { refreshIframe });
};
