import { baseApiUrl } from './helpers';

import { createResponseClass } from '../types/api.dto';
import { CheckMessageDto, UncheckedMessagesDto, UserMessageDto } from '../types/notifications.api.dto';

const { REACT_APP_API_URL_MESSAGES: apiMessages } = process.env;

export const UserMessagesResponse = createResponseClass(
	UserMessageDto,
	`${baseApiUrl('perevershnyk_utils', apiMessages)}/user-messages`,
	true
);
export const UncheckedMessagesResponse = createResponseClass(
	UncheckedMessagesDto,
	`${baseApiUrl('perevershnyk_utils', apiMessages)}/count-unchecked`
);
export const CheckMessagesResponse = (id: number) => {
	return createResponseClass(CheckMessageDto, `${baseApiUrl('perevershnyk_utils', apiMessages)}/check-message/${id}`);
};
