import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { usePopup } from '@temabit/perevershnyk-uikit/dist/Hooks';

import { useAppDispatch, useAppState } from '../context/store';
import { setActiveDataId, setIFrameParams } from '../context/actions';

import { instanceDtoValidate } from '../hooks/url.hooks/helpers';

import { NotificationDataDto } from '../types/notifications.api.dto';
import { IFrameParam } from '../context/types';

import warningIcon from '../assets/images/popup-warning.svg';

export const useOpenNotification = () => {
	const {
		modules: { list: modules },
		positions
	} = useAppState();
	const dispatch = useAppDispatch();

	const { pathname } = useLocation();
	const { push } = useHistory();

	const { popup, setPopupConfig, removePopup } = usePopup();

	const managePopup = useCallback(
		(popupContent: Parameters<typeof setPopupConfig>[0], icon?: string) => {
			setPopupConfig(popupContent, {
				iconUrl: icon,
				onClose: () => removePopup(),
				onSubmit: () => removePopup()
			});
		},
		[setPopupConfig, removePopup]
	);

	const callErrorPopup = useCallback(
		(message: string) => {
			const popupContent = (
				<>
					помилка даних нотифікації:
					<br />
					{message}
				</>
			);

			managePopup(popupContent, warningIcon);
		},
		[managePopup]
	);

	const openInfoNotification = useCallback(
		(body: NotificationDataDto['body']) => {
			if (typeof body === 'string') {
				managePopup(body);
			} else {
				const popupContent = (
					<>
						Щось пішло не так.
						<br />
						Відкрийте дане сповіщення
						<br />
						через "Центр сповіщень".
					</>
				);

				managePopup(popupContent);
			}
		},
		[managePopup]
	);

	const openModuleNotification = useCallback(
		(options: NonNullable<NotificationDataDto['options']>) => {
			const { operationName, parameters } = options;

			if (!operationName) {
				callErrorPopup('формат інформації');
			}

			const validInputData = [] as boolean[];
			let positionId = null as number | null;

			const moduleId = modules.findIndex((item) => item.operationName === operationName);
			validInputData.push(moduleId !== -1);

			if (validInputData.every((item) => item)) {
				const staffId = parameters.find((item: { key: string; value: string }) => item.key === 'staffId');

				if (staffId) {
					positionId = positions?.findIndex((item) => `${item.staffId}` === staffId.value) || null;
					validInputData.push(positionId !== -1);
				}
			}

			if (validInputData.every((item) => item)) {
				dispatch(setActiveDataId('moduleCoords', modules[moduleId].coords));

				if (typeof positionId === 'number') {
					dispatch(setActiveDataId('position', positionId));
				}

				const requiredOperationGroup = operationName.split('.')[0];

				const iframeParams = parameters.map<IFrameParam>((param) => {
					return {
						key: param.key,
						value: param.value,
						requiredOperationGroups: [requiredOperationGroup]
					};
				});

				dispatch(setIFrameParams(iframeParams));
			} else {
				console.log('valid module, position', ...validInputData);
				callErrorPopup(!validInputData[0] ? 'невідомий модуль' : 'невідома посада');
			}
		},
		[modules, positions, callErrorPopup, dispatch]
	);

	const openNotification = useCallback(
		(data: NotificationDataDto) => {
			try {
				const { options, body } = instanceDtoValidate(NotificationDataDto, data);

				if (!options) {
					openInfoNotification(body);
				} else {
					openModuleNotification(options);

					if (pathname !== '/') {
						push('/');
					}
				}
			} catch (error) {
				console.dir(error);
				console.log('notification data', data);

				callErrorPopup('формат інформації');
			}
		},
		[pathname, openInfoNotification, openModuleNotification, callErrorPopup, push]
	);

	return { openNotification, errorPopup: popup };
};
