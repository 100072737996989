import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { useAppDispatch, useAppState } from '../../context/store';
import { setActiveDataId } from '../../context/actions';

import { NotificationBtnProps } from './types';

import './notification.btn.scss';

import BellIcon from '../../assets/images/icons/bell.svg';

export const NotificationBtn: FC<NotificationBtnProps> = ({ hasUnread }) => {
	const {
		activeData: { moduleCoords }
	} = useAppState();

	const dispatch = useAppDispatch();

	const btnClickHandler = useCallback(() => {
		moduleCoords && dispatch(setActiveDataId('moduleCoords', null));
	}, [moduleCoords, dispatch]);

	return (
		<Link
			to="/notification-center"
			className={classNames('btn-notification-center', { 'unread-marker': hasUnread })}
			onClick={btnClickHandler}
		>
			<img src={BellIcon} alt="" />
		</Link>
	);
};
