import { FC, useMemo } from 'react';

import { useAppState } from '../../../context/store';

import { getPosition } from '../../../utils/helpers';

import './user.info.scss';

import UserImg from '../../../assets/images/icons/ava-plug.jpg';

export const UserInfo: FC = () => {
	const {
		positions,
		fio,
		activeData: { position: positionId }
	} = useAppState();

	const positionNameMemo = useMemo(() => {
		if (positions?.length === 1) {
			const position = getPosition(positions, positionId);
			return position?.positionName || '';
		}

		return '';
	}, [positions, positionId]);

	return (
		<div className="line line-user-info">
			<div className="wrap-img" style={{ backgroundImage: `url(${UserImg})` }}></div>
			<div className="user-data">
				<p className="name-user">{fio}</p>
				{positionNameMemo ? <p>{positionNameMemo}</p> : ''}
			</div>
		</div>
	);
};
