import { Action, IFrameParam, State } from './types';
import { Position } from '../types/api.dto';
import { AppActionTypes } from './action.types';
import { mappedModulesList } from '../utils/helpers';

const defaultStateUpdate = (state: State, action: Action): State => {
	return { ...state, ...action.payload };
};

const defaultIFrameParams = (positions: Position[], positionId: number | null): IFrameParam[] => {
	return typeof positionId === 'number'
		? [
				{
					key: 'staffId',
					value: `${positions[positionId].staffId}`,
					requiredOperationGroups: ['perevershnyk_schedule', 'perevershnyk_vacations', 'perevershnyk_insurance'],
					type: 'base'
				}
		  ]
		: [];
};

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case AppActionTypes.SetModules: {
			return defaultStateUpdate(state, action);
		}
		case AppActionTypes.SetPositions: {
			if (action.payload.positions.length) {
				return {
					...state,
					positions: action.payload.positions,
					activeData: { ...state.activeData, position: 0 },
					iFrameParams: defaultIFrameParams(action.payload.positions, 0)
				};
			} else {
				return defaultStateUpdate(state, action);
			}
		}
		case AppActionTypes.SetFio: {
			return defaultStateUpdate(state, action);
		}
		case AppActionTypes.SetUncheckedMessages: {
			return defaultStateUpdate(state, action);
		}
		case AppActionTypes.SetActiveDataId: {
			let modules = state.modules;

			if (action.payload.field === 'position' && state.positions) {
				modules = mappedModulesList(
					state.modules.tree,
					state.positions[action.payload.value as number].staffId,
					state.modules.originalTree
				);
			}

			return {
				...state,
				modules,
				activeData: { ...state.activeData, [action.payload.field]: action.payload.value },
				iFrameParams: state.positions?.length
					? defaultIFrameParams(
							state.positions,
							action.payload.field === 'position' ? (action.payload.value as number) : state.activeData.position
					  )
					: []
			};
		}
		case AppActionTypes.SetIFrameParams: {
			const mappedParams = action.payload.iFrameParams.map<IFrameParam>((item) => {
				const { key, value, requiredOperationGroups, type } = item;

				return { key, value, requiredOperationGroups, type: type || 'optional' };
			});

			return { ...state, iFrameParams: mappedParams };
		}
		case AppActionTypes.ResetIFrameParams: {
			const filteredParams = state.iFrameParams.filter((item) => {
				return item.type === 'base';
			});

			return { ...state, iFrameParams: filteredParams };
		}
		case AppActionTypes.SetShowLoading: {
			return defaultStateUpdate(state, action);
		}
		case AppActionTypes.SetRefreshIframe: {
			return defaultStateUpdate(state, action);
		}
		default: {
			console.error(`Unhandled action type: ${(action as Action).type}`);

			return { ...state };
		}
	}
};
