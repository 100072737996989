import { Exclude, Expose, Type } from 'class-transformer';
import {
	IsArray,
	IsDefined,
	IsInstance,
	IsInt,
	IsObject,
	IsOptional,
	IsPhoneNumber,
	IsString,
	Min,
	MinLength,
	ValidateIf,
	ValidateNested
} from 'class-validator';

import { ModulesValidationLogger, PositionsValidationLogger, UserInfoValidationLogger } from './validation.errors';

import type { AxiosError } from 'axios';
import type { responseInterface, SWRInfiniteResponseInterface } from 'swr';
import type { ClassConstructor } from 'class-transformer';

const API_URL_META_KEY = Symbol.for('api:url');

function registerApiUrl(target: Function, url: string) {
	Reflect.defineMetadata(API_URL_META_KEY, url, target);
}

export function getApiUrl(target: Function): string {
	const url = Reflect.getMetadata(API_URL_META_KEY, target);
	if (typeof url !== 'string') {
		throw new Error('Url was not provided, asshole..');
	}
	return url;
}

function ApiUrl(url: string): ClassDecorator {
	return (target) => {
		registerApiUrl(target, url);
	};
}

@Exclude()
export class Module {
	constructor(module: Module) {
		Object.assign(this, module);
	}

	@Expose()
	@IsString({ ...ModulesValidationLogger })
	@MinLength(1, { ...ModulesValidationLogger })
	public title: string;

	@Expose()
	@IsOptional()
	@IsString({ ...ModulesValidationLogger })
	@MinLength(1, { ...ModulesValidationLogger })
	public operationName?: string;

	@Expose()
	@IsOptional()
	@IsString({ ...ModulesValidationLogger })
	@MinLength(1, { ...ModulesValidationLogger })
	public url?: string;

	@Expose()
	@IsOptional()
	@IsArray()
	@IsInt({ each: true })
	@Min(1, { each: true })
	public positions?: number[];

	@Expose()
	@IsOptional()
	@Type(() => Module)
	@IsArray({ ...ModulesValidationLogger })
	@IsObject({ each: true, ...ModulesValidationLogger })
	@ValidateNested({ each: true, ...ModulesValidationLogger })
	public childNodes?: Module[];

	public coords: number[];
}

@Exclude()
export class Position {
	@Expose()
	@IsInt({ ...PositionsValidationLogger })
	@Min(1, { ...PositionsValidationLogger })
	public staffId: number;

	@Expose()
	@IsString({ ...PositionsValidationLogger })
	public positionName: string;
}

@Exclude()
export class ModulesDto {
	@Expose()
	@Type(() => Module)
	@IsDefined()
	@IsArray()
	@IsObject({ each: true })
	@ValidateNested({ each: true })
	public modules: Module[];

	@Expose()
	@Type(() => Position)
	@IsDefined()
	@IsArray()
	@IsObject({ each: true })
	@ValidateNested({ each: true })
	public positions: Position[];
}

@Exclude()
export class UserInfoDto {
	@Expose()
	@IsString({ ...UserInfoValidationLogger })
	public fio: string;

	@Expose()
	@IsString({ ...UserInfoValidationLogger })
	public login: string;

	@Expose()
	@IsString({ ...UserInfoValidationLogger })
	@ValidateIf((obj, value) => value)
	@IsPhoneNumber()
	public phone: string;
}

export interface MyResponse<T> {
	data: T;
}

export function createResponseClass<Cls extends ClassConstructor<any>>(
	cls: Cls,
	url: string,
	array?: false
): ClassConstructor<MyResponse<InstanceType<Cls>>>;
export function createResponseClass<Cls extends ClassConstructor<any>>(
	cls: Cls,
	url: string,
	array: true
): ClassConstructor<MyResponse<InstanceType<Cls>[]>>;
export function createResponseClass<Cls extends ClassConstructor<any>>(
	cls: Cls,
	url: string,
	array?: boolean
): ClassConstructor<MyResponse<InstanceType<Cls> | MyResponse<Cls>[]>> {
	if (array) {
		@Exclude()
		@ApiUrl(url)
		class _Response implements MyResponse<InstanceType<Cls> | InstanceType<Cls>[]> {
			@Expose()
			@Type(() => cls)
			@IsDefined()
			@IsArray()
			@IsObject({ each: true })
			@IsInstance(cls, { each: true })
			@ValidateNested({
				each: true
			})
			data: InstanceType<Cls> | InstanceType<Cls>[];
		}

		try {
			Object.defineProperty(_Response, 'name', {
				configurable: false,
				enumerable: false,
				writable: false,
				value: `MyResponse<${cls.name}[]>`
			});
		} catch (error) {
			console.warn(error);
		}

		return _Response;
	} else {
		@Exclude()
		@ApiUrl(url)
		class _Response implements MyResponse<InstanceType<Cls> | InstanceType<Cls>[]> {
			@Expose()
			@Type(() => cls)
			@IsDefined()
			@IsObject()
			@IsInstance(cls)
			@ValidateNested({
				each: true
			})
			data: InstanceType<Cls> | InstanceType<Cls>[];
		}

		try {
			Object.defineProperty(_Response, 'name', {
				configurable: false,
				enumerable: false,
				writable: false,
				value: `MyResponse<${cls.name}>`
			});
		} catch (error) {
			console.warn(error);
		}

		return _Response;
	}
}

export interface DefaultError {
	id: string;
	error: string;
}

export interface SWRError<T = any> extends AxiosError<T> {
	userMessage?: string;
}

export interface SWRResponse<Cls>
	extends responseInterface<Cls extends ClassConstructor<MyResponse<infer R>> ? R : unknown, SWRError> {
	isLoading: boolean;
}

export interface SWRInfiniteResponse<Cls>
	extends SWRInfiniteResponseInterface<Cls extends ClassConstructor<MyResponse<infer R>> ? R : unknown, SWRError> {
	isLoading: boolean;
}
