import { useCallback, useEffect, useMemo, useRef } from 'react';
import { AxiosResponse } from 'axios';

import useSWR, { useSWRInfinite } from 'swr';

import { ClassConstructor } from 'class-transformer';

import { useAppState, useAppDispatch } from '../../context/store';
import { setModules, setPositions, setUncheckedMessages } from '../../context/actions';

import { getData, instanceClsValidate } from './helpers';
import { isFZCoreError } from './guards';
import { ModulesResponse } from '../../utils/api';

import { MyResponse, SWRResponse, SWRInfiniteResponse, getApiUrl, SWRError } from '../../types/api.dto';

import { headers } from '../../index';
import { CheckMessagesResponse } from '../../utils/notifications.api';

export const useClsSWR = <Cls extends ClassConstructor<MyResponse<any>>>(
	cls: Cls | null,
	headers?: any,
	paramsPartStr?: string
): SWRResponse<Cls> => {
	const { client } = useAppState();

	const errorRetryCountRef = useRef<number>(1);

	const skipRetry = useCallback((error: SWRError, retryCount: number, ...addRules: boolean[]) => {
		const skipRules = [] as boolean[];
		skipRules.push(Boolean(error.response));
		skipRules.push(error.config?.url === error.request?.responseURL);
		skipRules.push(Boolean((retryCount || 0) >= 2));

		addRules.length && skipRules.push(...addRules);

		return skipRules.indexOf(true) !== -1;
	}, []);

	const swrResponse = useSWR(cls ? [cls, client, headers, paramsPartStr] : null, getData, {
		revalidateOnFocus: false,
		onErrorRetry: async (error, key, config, revalidate, { retryCount }) => {
			if (skipRetry(error, retryCount || 0)) {
				return;
			}

			console.log('retry fetching error request');
			errorRetryCountRef.current++;
			await revalidate({ retryCount: 1 });
		},
		onError: (error) => {
			console.log('request failed');
			console.dir(error);
		}
	}) as SWRResponse<Cls>;

	const isLoadingMemo = useMemo(() => {
		const error = swrResponse.error;
		const retryCount = errorRetryCountRef.current;

		if (error && !skipRetry(error, retryCount, retryCount < 2)) {
			return true;
		}

		return !!cls && (swrResponse.isValidating || (!error && !swrResponse.data));
	}, [cls, swrResponse.isValidating, swrResponse.error, swrResponse.data, skipRetry]);

	const errorMemo = useMemo(() => {
		const error = swrResponse.error;

		if (isFZCoreError(error)) {
			error.userMessage = error.response.data;
		}

		if (error && skipRetry(error, errorRetryCountRef.current)) {
			return error;
		}
	}, [swrResponse.error, skipRetry]);

	return { ...swrResponse, isLoading: isLoadingMemo, error: errorMemo };
};

export const useClsSWRInfinite = <Cls extends ClassConstructor<MyResponse<any>>>(
	cls: Cls,
	paramsPartStr: (index: number) => string,
	headers?: any
): SWRInfiniteResponse<Cls> => {
	const { client } = useAppState();

	const swrResponse = useSWRInfinite((index) => [cls, client, headers, paramsPartStr(index)], getData, {
		shouldRetryOnError: false,
		revalidateOnFocus: false
	}) as SWRInfiniteResponse<Cls>;

	const errorMemo = useMemo(() => {
		const error = swrResponse.error;

		if (isFZCoreError(error)) {
			error.userMessage = error.response.data;
		}

		return error;
	}, [swrResponse.error]);

	return { ...swrResponse, error: errorMemo };
};

export const useInitData = () => {
	const dispatch = useAppDispatch();

	const {
		data: modulesData,
		error: modulesError,
		isLoading: isModulesLoading,
		revalidate: modulesRevalidate
	} = useClsSWR(ModulesResponse, headers.modules);

	const errorMemo = useMemo(() => {
		if ((modulesError as any)?.id !== '0') {
			return modulesError;
		}
	}, [modulesError]);

	const isLoadingMemo = useMemo(() => {
		if ((modulesError as any)?.id === '0') {
			return true;
		}

		return isModulesLoading;
	}, [isModulesLoading, modulesError]);

	const revalidate = useCallback(async () => {
		await modulesRevalidate();
	}, [modulesRevalidate]);

	useEffect(() => {
		if (modulesData) {
			dispatch(setModules(modulesData.modules, modulesData.positions[0]?.staffId));
			dispatch(setPositions(modulesData.positions));
		}
	}, [modulesData, dispatch]);

	return { data: modulesData, error: errorMemo, isLoading: isLoadingMemo, revalidate };
};

export const useCheckMessage = () => {
	const { client } = useAppState();
	const dispatch = useAppDispatch();

	const checkMessage = useCallback(
		async (id: number) => {
			try {
				const cls = CheckMessagesResponse(id);
				const response = (await client.put(getApiUrl(cls), {}, { headers: { ...headers.positions } })) as AxiosResponse<
					typeof cls
				>;
				const instance = instanceClsValidate(cls, response) as typeof cls extends ClassConstructor<MyResponse<infer R>>
					? R
					: unknown;
				console.log(instance);

				!instance.unchecked && dispatch(setUncheckedMessages(false));

				return instance;
			} catch (error) {
				console.log('error', error);
			}
		},
		[client, dispatch]
	);

	return checkMessage;
};
