import { isURL } from 'class-validator';

import { IFrameParam } from '../context/types';

import { Module, Position } from '../types/api.dto';

export const baseApiUrl = (moduleName: string, endpoint?: string) => {
	if (endpoint) {
		return endpoint;
	}

	return `https://${window.location.hostname}/api/perevershnyk/${moduleName}`;
};

export const isAbsoluteURL = (url: string) => {
	const result = isURL(url, { require_host: true });
	return result;
};

export const iframeSrc = (module: Module, params: IFrameParam[]) => {
	const locationOrigin = window.location.origin;
	let url = {} as URL;

	if (module.url) {
		url = new URL(module.url.indexOf(locationOrigin) !== -1 ? module.url : locationOrigin + module.url);
		url.href += url.href.endsWith('/') ? '' : '/';
	} else {
		url = new URL('');
	}

	params.forEach((item) => {
		const operationGroup = module.operationName?.split('.')[0];

		if (operationGroup && item.requiredOperationGroups.indexOf(operationGroup) !== -1) {
			url.searchParams.set(item.key, item.value);
		}
	});

	console.log(url.href);
	return url.href;
};

export const modulesDeepCopy = (modules: Module[]) => {
	let resultModule = [] as Module[];

	modules.forEach((item) => {
		resultModule.push(
			new Module({
				...item,
				childNodes: item.childNodes ? [...modulesDeepCopy(item.childNodes)] : ([] as Module[])
			})
		);
	});

	return resultModule;
};

const setModulesCoords = (modules: Module[], levelCoords = [] as number[]) => {
	return modules.map((item, i) => {
		const currentLevelCoords = [...levelCoords];

		currentLevelCoords.push(i);
		item.coords = currentLevelCoords;

		if (item.childNodes?.length) {
			setModulesCoords(item.childNodes, item.coords);
		}
		return item;
	});
};

const flatModulesList = (modules: Module[]) => {
	const result = [] as Module[];

	modules.forEach((item) => {
		if (item.childNodes?.length) {
			const subList = flatModulesList(item.childNodes);

			result.push(...subList);
		} else {
			result.push(item);
		}
	});

	return result;
};

const filterModulesTree = (tree: Module[], filterValue: number) => {
	let result = [] as Module[];

	result = tree.filter((item) => {
		if (item.childNodes?.length) {
			const subList = filterModulesTree(item.childNodes, filterValue);

			item.childNodes = subList;
			return !!item.childNodes.length;
		}

		return item.positions?.indexOf(filterValue) !== -1;
	});

	return result;
};

export const mappedModulesList = (modules: Module[], staffId?: number, originalTree?: Module[]) => {
	const result = {
		list: [] as Module[],
		tree: [] as Module[],
		originalTree: [] as Module[]
	};

	result.originalTree = originalTree || setModulesCoords(modulesDeepCopy(modules));
	result.tree =
		typeof staffId === 'number'
			? filterModulesTree(modulesDeepCopy(result.originalTree), staffId)
			: result.originalTree;
	result.list = flatModulesList(modulesDeepCopy(result.tree));

	return result;
};

export const getPosition = (positions: Position[] | null, positionId: number | null) => {
	let validProgress = true;

	validProgress = !!positions;
	validProgress = validProgress && !!positions?.length;
	validProgress = validProgress && typeof positionId === 'number';

	if (validProgress) {
		return (positions as Position[])[positionId as number];
	}

	return null;
};
