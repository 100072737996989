import { FC, useEffect } from 'react';

import { MainScreen } from './pages/main.screen';

import { Header } from './components/header';

import { useAppDispatch } from './context/store';
import { setFio, setUncheckedMessages } from './context/actions';

import { RoutesProvider } from './hoc/routes.provider';

import { useClsSWR, useInitData } from './hooks/url.hooks';
import { useBlockRender } from './hooks/use.block.render';

import { UserInfoResponse } from './utils/api';
import { UncheckedMessagesResponse } from './utils/notifications.api';

import { headers } from './index';

export const AppDesktop: FC = () => {
	const dispatch = useAppDispatch();

	const { data: initData, error: initDataError, isLoading: isInitDataLoading } = useInitData();

	const { data: userInfoData, error: userInfoError, isLoading: isUserInfoLoading } = useClsSWR(
		initData ? UserInfoResponse : null,
		headers.modules
	);

	const { data: uncheckedData, error: uncheckedError } = useClsSWR(
		initData ? UncheckedMessagesResponse : null,
		headers.positions
	);

	const blockRender = useBlockRender(
		isInitDataLoading || isUserInfoLoading,
		true,
		initDataError || userInfoError || uncheckedError
	);

	useEffect(() => {
		uncheckedData && dispatch(setUncheckedMessages(!!uncheckedData.count));
	}, [uncheckedData, dispatch]);

	useEffect(() => {
		userInfoData && dispatch(setFio(userInfoData.fio));
	}, [userInfoData, dispatch]);

	if (blockRender) {
		return blockRender;
	}

	return (
		<div className="App">
			<Header />
			<main>
				<RoutesProvider>
					<MainScreen />
				</RoutesProvider>
			</main>
		</div>
	);
};
