import { FC, useMemo } from 'react';
import { News as NewsWidget } from '@temabit/perevershnyk-uikit/dist/Widgets';

import { Widget } from '../widget';

import { useClsSWR } from '../../../hooks/url.hooks';

import { NewsResponse } from '../../../utils/widgets.api';

import { WidgetProviderProps } from '../types';

import { headers } from '../../..';

const getParamUrlPart = () => '0/2';

export const NewsWidgetProvider: FC<WidgetProviderProps> = ({ moduleBtnTitle, moduleCoords, title }) => {
	const {
		data: newsData,
		isLoading: isNewsLoading,
		error: newsError,
		revalidate: revalidateNews
	} = useClsSWR(NewsResponse, headers.positions, getParamUrlPart());

	const revalidateFnMemo = useMemo(() => (newsError ? revalidateNews : null), [newsError, revalidateNews]);

	return (
		<Widget
			moduleBtnTitle={moduleBtnTitle}
			moduleCoords={moduleCoords}
			isEmpty={!newsData?.length}
			isError={!!newsError}
			isLoading={isNewsLoading}
			retryFn={revalidateFnMemo}
			title={title}
		>
			<NewsWidget data={newsData || null} />
		</Widget>
	);
};
