import { FC, Fragment, ReactNode, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Spinner } from '@temabit/perevershnyk-uikit/dist/Components';
import { useTempInfoBlock, useAnalytics } from '@temabit/perevershnyk-uikit/dist/Hooks';

import { useAppDispatch } from '../../../context/store';
import { setActiveDataId, setIFrameParams } from '../../../context/actions';

import { WidgetProps, ButtonConfigType } from './types';

import './widget.scss';

const defaultStrings = {
	title: 'віджет',
	btnTitles: {
		module: 'перейти в модуль',
		retry: 'спробувати ще раз'
	},
	contentTitles: {
		noModuleCoords: 'модуль не знайдено',
		error: 'при завантаженні виникла помилка',
		empty: 'тут порожньо...'
	},
	infoBlockTitle: process.env.REACT_APP_RECEIVE_DATA_ERROR
};

export const Widget: FC<WidgetProps> = ({
	empty,
	error,
	isEmpty = false,
	isError = false,
	isLoading = false,
	loading,
	main,
	moduleBtnTitle = defaultStrings.btnTitles.module,
	moduleCoords,
	additionalParams,
	retryFn,
	title = defaultStrings.title,
	children
}) => {
	const dispatch = useAppDispatch();

	const { toggleEventGa } = useAnalytics();
	const { tempInfoBlock, displayTempInfo } = useTempInfoBlock();

	const widgetReadyRef = useRef<boolean>(false);

	const openModule = useCallback(() => {
		toggleEventGa('Main', 'widget-open-module_button', `open module from widget "${title}"`);

		if (additionalParams) {
			dispatch(setIFrameParams(additionalParams));
		}

		dispatch(setActiveDataId('moduleCoords', moduleCoords!));
	}, [title, moduleCoords, additionalParams, toggleEventGa, dispatch]);

	const getTitle = useCallback(
		(configTitle?: string, visible?: boolean) => {
			const stateTitle = typeof configTitle === 'string' ? configTitle : title;

			let titleNode = (<div className="title widget-title">{stateTitle}</div>) as ReactNode;

			if (visible === false) {
				titleNode = '';
			}

			return titleNode;
		},
		[title]
	);

	const getInfo = useCallback((defaultText: string, buttonVisible?: boolean, customText?: string) => {
		let text = defaultText;

		if (typeof customText === 'string') {
			text = customText;
		}

		return <div className={classNames('widget-info', { 'no-button': buttonVisible === false })}>{text}</div>;
	}, []);

	const getButton = useCallback((title: string, handler: () => void): ReactNode => {
		return (
			<button className="btn widget-btn" onClick={handler}>
				{title}
			</button>
		);
	}, []);

	const getCustomButton = useCallback(
		(btnConfig: ButtonConfigType, defaultTitle?: string, defaultHandler?: (() => void) | null) => {
			let btnTitle = defaultTitle || 'default button';
			let handler = defaultHandler || (() => console.log('handler unset'));

			switch (btnConfig.default) {
				case 'module':
					btnTitle = moduleBtnTitle;
					handler = openModule;
					break;

				case 'retry':
					btnTitle = defaultStrings.btnTitles.retry;
					handler = retryFn || handler;
					break;

				default:
					btnTitle = typeof btnConfig.title === 'string' ? btnConfig.title : btnTitle;
					handler = typeof btnConfig.handler === 'function' ? btnConfig.handler : handler;
					break;
			}

			return getButton(btnTitle, handler);
		},
		[moduleBtnTitle, openModule, getButton, retryFn]
	);

	const getComponent = useCallback(
		(...args: ReactNode[]) => {
			return (
				<div className="widget box-module-screen">
					{tempInfoBlock}
					{args.map((item, i) => (
						<Fragment key={i}>{item}</Fragment>
					))}
				</div>
			) as ReactNode;
		},
		[tempInfoBlock]
	);

	const setErrorState = useCallback(() => {
		const titleNode = getTitle(error?.title, error?.titleVisible);

		let btnNode = error?.button
			? getCustomButton(error.button, defaultStrings.btnTitles.retry, retryFn)
			: getButton(defaultStrings.btnTitles.retry, async () => {
					if (retryFn) {
						await retryFn();
					} else {
						console.log(`Widget "${title}" says: nothing to revalidate`);
					}
			  });

		if (error?.buttonVisible === false) {
			btnNode = '';
		}

		let contentNode = getInfo(
			isError ? defaultStrings.contentTitles.error : defaultStrings.contentTitles.noModuleCoords,
			error?.buttonVisible,
			error?.info
		) as ReactNode;

		if (error?.content) {
			contentNode = error.content;
		}

		return getComponent(titleNode, contentNode, btnNode);
	}, [error, isError, title, getTitle, getInfo, getButton, getCustomButton, getComponent, retryFn]);

	const setLoadingState = useCallback(() => {
		const titleNode = getTitle(loading?.title, loading?.titleVisible);

		let btnNode = loading?.button ? getCustomButton(loading?.button) : '';

		if (loading?.buttonVisible === false) {
			btnNode = '';
		}

		const infoNode = typeof loading?.info === 'string' ? getInfo(loading.info, loading?.buttonVisible || false) : '';

		let contentNode = (
			<div className="widget-loading">
				<Spinner.Component />
				{infoNode}
			</div>
		) as ReactNode;

		if (loading?.content) {
			contentNode = loading.content;
		}

		return getComponent(titleNode, contentNode, btnNode);
	}, [loading, getTitle, getInfo, getCustomButton, getComponent]);

	const setEmptyState = useCallback(() => {
		const titleNode = getTitle(empty?.title, empty?.titleVisible);

		let btnNode = empty?.button
			? getCustomButton(empty.button, moduleBtnTitle, openModule)
			: getButton(moduleBtnTitle, openModule);

		if (empty?.buttonVisible === false) {
			btnNode = '';
		}

		let contentNode = getInfo(defaultStrings.contentTitles.empty, empty?.buttonVisible, empty?.info) as ReactNode;

		if (empty?.content) {
			contentNode = empty.content;
		}

		return getComponent(titleNode, contentNode, btnNode);
	}, [moduleBtnTitle, empty, getTitle, getInfo, getButton, getCustomButton, getComponent, openModule]);

	const setMainState = useCallback(() => {
		const titleNode = getTitle(main?.title, main?.titleVisible);

		let btnNode = main?.button
			? getCustomButton(main.button, moduleBtnTitle, openModule)
			: getButton(moduleBtnTitle, openModule);

		if (main?.buttonVisible === false) {
			btnNode = '';
		}

		let contentNode = (<div className="widget-content">{children}</div>) as ReactNode;

		if (main?.content) {
			contentNode = main.content;
		}

		return getComponent(titleNode, contentNode, btnNode);
	}, [moduleBtnTitle, main, children, getTitle, getButton, getCustomButton, openModule, getComponent]);

	useEffect(() => {
		if (widgetReadyRef.current && isError && !isLoading) {
			displayTempInfo(defaultStrings.infoBlockTitle);
		}
	}, [isLoading, isError, displayTempInfo]);

	useEffect(() => {
		if (!widgetReadyRef.current && !isLoading) {
			widgetReadyRef.current = true;
		}
	}, [isLoading]);

	if (isLoading) {
		return <>{setLoadingState()}</>;
	}

	if (isError || !moduleCoords) {
		return <>{setErrorState()}</>;
	}

	if (isEmpty) {
		return <>{setEmptyState()}</>;
	}

	return <>{setMainState()}</>;
};
