import { FC, useCallback, useEffect, useRef } from 'react';
import { NATIVE_RPC_EVENTS } from '@temabit/perevershnyk-rpc-shell/dist/mobile/events.enum';

import { useOpenNotification } from '../hooks/use.open.notification';
import { useCheckMessage } from '../hooks/url.hooks';

import { useAppState } from '../context/store';

import { RegisterFCMRequest } from '../utils/api';

import { TokenType } from '../types/App';
import { NotificationDataDto } from '../types/notifications.api.dto';
import { plainToClass } from 'class-transformer';
import { validateSync } from 'class-validator';

export const RpcProvider: FC = ({ children }) => {
	const {
		client,
		rpc,
		modules: { tree: modules },
		positions
	} = useAppState();

	const notificationDataRef = useRef<NotificationDataDto | null>(null);

	const { openNotification, errorPopup } = useOpenNotification();

	const checkNotification = useCheckMessage();

	const updateFCMToken = useCallback(
		async ({ token }: TokenType) => {
			console.log('GET FCM TOKEN');
			const body = { deviceToken: token };
			try {
				await client!.post(RegisterFCMRequest, body);
			} catch (e) {
				//dispatch error
				console.log(e, 'fcm update error');
			}
		},
		[client]
	);

	const proceedNotification = useCallback(
		(notificationData: NotificationDataDto) => {
			console.log('base data received, calling openNotification()');
			openNotification(notificationData);

			try {
				checkNotification(notificationData.messageId);
			} catch (error) {
				console.log('error during message check');
				console.log(error);
				console.log(JSON.stringify(error));
			}
		},
		[openNotification, checkNotification]
	);

	const handleNotificationOpen = useCallback(
		(data: Record<string, unknown>) => {
			console.log('notification opened event');
			const notificationData = plainToClass(NotificationDataDto, data);
			const validationErrors = validateSync(notificationData);

			if (validationErrors.length) {
				validationErrors.forEach((item) => {
					console.dir(item);
				});

				return;
			}

			if (modules.length && positions) {
				proceedNotification(notificationData);
			} else {
				!modules.length && console.log('missing modules list');
				!positions && console.log('missing positions list');

				console.log('storing notification until base data is received');
				notificationDataRef.current = notificationData;
			}
		},
		[modules.length, positions, proceedNotification]
	);

	const delayedOpenNotification = useCallback(() => {
		if (notificationDataRef.current) {
			proceedNotification(notificationDataRef.current);
			notificationDataRef.current = null;
		}
	}, [proceedNotification]);

	useEffect(() => {
		modules.length && positions && delayedOpenNotification();
	}, [modules.length, positions, delayedOpenNotification]);

	useEffect(() => {
		rpc.subscribe(NATIVE_RPC_EVENTS.NOTIFICATION_OPENED_EVENT, handleNotificationOpen);
		rpc.subscribe(NATIVE_RPC_EVENTS.FIREBASE_UPDATE_TOKEN_EVENT, updateFCMToken);
		return () => {
			rpc.unsubscribe(NATIVE_RPC_EVENTS.NOTIFICATION_OPENED_EVENT);
			rpc.unsubscribe(NATIVE_RPC_EVENTS.FIREBASE_UPDATE_TOKEN_EVENT);
		};
	}, [rpc, handleNotificationOpen, updateFCMToken]);

	return (
		<>
			{children}
			{errorPopup}
		</>
	);
};
