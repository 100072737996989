import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Schedule as ScheduleWidget } from '@temabit/perevershnyk-uikit/dist/Widgets';
import { formattedDate } from '@temabit/perevershnyk-uikit/dist/utils/format.date';

import { Widget } from '../widget';

import { useClsSWR } from '../../../hooks/url.hooks';

import { useAppState } from '../../../context/store';

import { ScheduleAPI } from '../../../utils/widgets.api';
import { getPosition } from '../../../utils/helpers';

import { DateDependWidgetProviderProps } from '../types';

import { headers } from '../../../index';

const infoTitles = {
	noSchedule: 'сьогодні у вас вихідний',
	noPositions: 'відсутні доступні посади'
};

export const ScheduleWidgetProvider: FC<DateDependWidgetProviderProps> = ({
	date,
	moduleBtnTitle,
	moduleCoords,
	title
}) => {
	const {
		activeData: { position: positionId },
		positions
	} = useAppState();

	const [isAdmin, setIsAdmin] = useState<boolean | null>(null);

	const stringDateRef = useRef<string>(formattedDate(new Date(date), 'YYYYMMDD'));

	const staffIdMemo = useMemo(() => {
		const position = getPosition(positions, positionId);

		return position ? position.staffId : null;
	}, [positions, positionId]);

	const getParamUrlPart = useCallback(() => {
		if (staffIdMemo) {
			return `${staffIdMemo}/${stringDateRef.current}`;
		}

		return '';
	}, [staffIdMemo]);

	const {
		data: personalInfoData,
		isLoading: isPersonalInfoLoading,
		error: personalInfoError,
		revalidate: revalidatePersonalInfo
	} = useClsSWR(staffIdMemo ? ScheduleAPI.PersonalInfoResponse : null, headers.positions, getParamUrlPart());

	const {
		data: lineData,
		isLoading: isLineLoading,
		error: lineError,
		revalidate: revalidateLine
	} = useClsSWR(isAdmin === false ? ScheduleAPI.LineResponse : null, headers.positions, getParamUrlPart());

	const {
		data: adminData,
		isLoading: isAdminLoading,
		error: adminError,
		revalidate: revalidateAdmin
	} = useClsSWR(isAdmin === true ? ScheduleAPI.AdminResponse : null, headers.positions, getParamUrlPart());

	const revalidateFnMemo = useMemo(() => {
		if (personalInfoError) {
			return revalidatePersonalInfo;
		}
		if (lineError) {
			return revalidateLine;
		}
		if (adminError) {
			return revalidateAdmin;
		}

		return null;
	}, [personalInfoError, lineError, adminError, revalidatePersonalInfo, revalidateLine, revalidateAdmin]);

	const isEmptyMemo = useMemo(() => {
		return !(lineData?.data.shiftStages.length || adminData?.data.shiftCode);
	}, [lineData, adminData]);

	const isErrorMemo = useMemo(() => {
		return !!(!staffIdMemo || personalInfoError || lineError || adminError);
	}, [staffIdMemo, personalInfoError, lineError, adminError]);

	const isLoadingMemo = useMemo(() => {
		if (isPersonalInfoLoading) {
			return true;
		}

		if (typeof isAdmin === 'boolean') {
			return isAdmin ? isAdminLoading : isLineLoading;
		}

		return false;
	}, [isPersonalInfoLoading, isAdmin, isLineLoading, isAdminLoading]);

	const dataMemo = useMemo(() => {
		return lineData?.data || adminData?.data || null;
	}, [lineData, adminData]);

	const errorInfoMemo = useMemo(() => {
		const error = personalInfoError || lineError || adminError;

		if (error?.response?.status === 400) {
			return (error.response.data?.message as string) || '';
		}

		if (!staffIdMemo) {
			return infoTitles.noPositions;
		}

		return '';
	}, [personalInfoError, lineError, adminError, staffIdMemo]);

	const customErrorConfigMemo = useMemo(() => {
		return errorInfoMemo ? { info: errorInfoMemo, buttonVisible: false } : {};
	}, [errorInfoMemo]);

	useEffect(() => {
		if (personalInfoData && !personalInfoError) {
			setIsAdmin((isAdmin) => {
				return typeof isAdmin === 'boolean' ? isAdmin : personalInfoData.data.isAdmin;
			});
		}
	}, [personalInfoData, personalInfoError]);

	return (
		<Widget
			empty={{ info: infoTitles.noSchedule }}
			error={customErrorConfigMemo}
			isEmpty={isEmptyMemo}
			isError={isErrorMemo}
			isLoading={isLoadingMemo}
			moduleBtnTitle={moduleBtnTitle}
			moduleCoords={moduleCoords}
			retryFn={revalidateFnMemo}
			title={title}
		>
			<ScheduleWidget data={dataMemo} />
		</Widget>
	);
};
