import { Route, Switch } from 'react-router-dom';

import { NotificationCenterProvider } from '../pages/notification.center/provider';

import type { FC } from 'react';

export const RoutesProvider: FC = ({ children }) => {
	return (
		<Switch>
			<Route exact path="/">
				{children}
			</Route>
			<Route exact path="/notification-center">
				<NotificationCenterProvider />
			</Route>
		</Switch>
	);
};
