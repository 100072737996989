import { FC, useEffect, useRef, useCallback, useState } from 'react';
import { RPC } from '@mixer/postmessage-rpc';
import { NATIVE_RPC_EVENTS, NATIVE_RPC_METHODS } from '@temabit/perevershnyk-rpc-shell/dist/mobile/events.enum';
import { useAnalytics } from '@temabit/perevershnyk-uikit/dist/Hooks';

import { MainScreen } from './pages/main.screen';

import { Header } from './components/header';

import { RoutesProvider } from './hoc/routes.provider';

import { useClsSWR, useInitData } from './hooks/url.hooks';
import { useBlockRender } from './hooks/use.block.render';

import { useAppDispatch, useAppState } from './context/store';
import { setFio, setUncheckedMessages } from './context/actions';

import { RegisterFCMRequest, UserInfoResponse } from './utils/api';
import { UncheckedMessagesResponse } from './utils/notifications.api';
import { getPosition } from './utils/helpers';

import { CacheObjType, TokenType } from './types/App';
import { UserInfoDto } from './types/api.dto';

import { headers } from './index';

export const AppMobile: FC = () => {
	const { pageViewGa } = useAnalytics();

	const {
		rpc: globalRPC,
		client,
		activeData: { position: positionId },
		positions
	} = useAppState();
	const dispatch = useAppDispatch();

	const {
		data: initData,
		error: initDataError,
		isLoading: isInitDataLoading,
		revalidate: revalidateInitData
	} = useInitData();

	const {
		data: userInfoData,
		error: userInfoError,
		isLoading: isUserInfoLoading
	} = useClsSWR(initData ? UserInfoResponse : null, headers.modules);

	const { data: uncheckedData, error: uncheckedError } = useClsSWR(
		initData ? UncheckedMessagesResponse : null,
		headers.positions
	);

	const blockRender = useBlockRender(
		isInitDataLoading || isUserInfoLoading,
		true,
		initDataError || userInfoError || uncheckedError
	);

	const [isIframeUpdated, toggleIsIframeUpdated] = useState<boolean>(false);

	const mainScreenRef = useRef<HTMLIFrameElement>(null);
	const RPCRef = useRef<RPC | null>(null);

	const registerDevice = useCallback(async () => {
		try {
			const {
				token: deviceToken,
				deviceId,
				platform: devicePlatform
			} = await globalRPC.request(NATIVE_RPC_METHODS.FIREBASE_GET_TOKEN, {});
			const platform = devicePlatform.toLowerCase();

			const appVersion = await globalRPC.request('appVersion:get', {});
			const version = appVersion[platform];
			const reqBody = { deviceToken, deviceId, platform, version };
			console.log('FIREBASE_GET_TOKEN', reqBody);
			await client?.post(RegisterFCMRequest, reqBody, { ...headers.modules });
		} catch (error) {
			console.log('FCM error', error);
		}
	}, [client, globalRPC]);

	const updateAccessToken = useCallback(
		async ({ token }: TokenType) => {
			try {
				console.log('refreshing request');
				await revalidateInitData();
			} catch (e) {
				//dispatch error
				console.log(e, 'access update falls');
			}
		},
		[revalidateInitData]
	);

	const saveUserSettings = useCallback(
		async (userInfo: UserInfoDto) => {
			try {
				console.log(positions, positionId);
				console.log(...Object.values(userInfo));
				const position = getPosition(positions, positionId);
				const positionName = position?.positionName || '';

				const cacheObj: CacheObjType = {
					fio: userInfo.fio,
					login: userInfo.login,
					phone: userInfo.phone,
					position: positionName
				};

				console.log('cache:save', JSON.stringify(cacheObj));
				await globalRPC.request('cache:save', cacheObj);
			} catch (e) {
				console.log(e, 'error cache save');
			}
		},
		[globalRPC, positionId, positions]
	);

	const iframeUpdateCallback = useCallback(() => {
		toggleIsIframeUpdated((isIframeUpdated) => !isIframeUpdated);
	}, []);

	useEffect(() => {
		pageViewGa('Main');
	}, [pageViewGa]);

	useEffect(() => {
		uncheckedData && dispatch(setUncheckedMessages(!!uncheckedData.count));
	}, [uncheckedData, dispatch]);

	useEffect(() => {
		if (userInfoData) {
			saveUserSettings(userInfoData);
			dispatch(setFio(userInfoData.fio));
		}
	}, [userInfoData, saveUserSettings, dispatch]);

	useEffect(() => {
		initData && registerDevice();
	}, [initData, registerDevice]);

	useEffect(() => {
		if (mainScreenRef.current) {
			console.log('RPC expose');
			RPCRef.current = new RPC({
				target: mainScreenRef.current!.contentWindow as any,
				serviceId: 'shell'
			});

			RPCRef.current.expose('request', ({ method, params }: any) => {
				return globalRPC.request(method, params);
			});

			return () => {
				console.log('RPC destroy');
				RPCRef.current!.destroy();
			};
		}
	}, [globalRPC, isIframeUpdated]);

	useEffect(() => {
		globalRPC.subscribe(NATIVE_RPC_EVENTS.ACCESS_TOKEN_UPDATE_EVENT, updateAccessToken);
		return () => {
			globalRPC.unsubscribe(NATIVE_RPC_EVENTS.ACCESS_TOKEN_UPDATE_EVENT);
		};
	}, [globalRPC, updateAccessToken]);

	if (blockRender) {
		return blockRender;
	}

	return (
		<div className="App">
			<Header />
			<main>
				<RoutesProvider>
					<MainScreen ref={mainScreenRef} onIframeRefresh={iframeUpdateCallback} />
				</RoutesProvider>
			</main>
		</div>
	);
};
