import { ValidationArguments } from 'class-validator/types/validation/ValidationArguments';

const validationMessage = 'Помилка валідації';

type messageType = { message: string | ((validationArguments: ValidationArguments) => string) };

export const UserInfoValidationLogger: messageType = {
	message: (options: ValidationArguments) => `"${options.property}" ${validationMessage} інформації про користувача`
};
export const ModulesValidationLogger: messageType = {
	message: (options: ValidationArguments) => `"${options.property}" ${validationMessage} списку модулів`
};
export const PositionsValidationLogger: messageType = {
	message: (options: ValidationArguments) => `"${options.property}"  ${validationMessage} списку посад`
};
export const UserMessageValidationLogger: messageType = {
	message: (options: ValidationArguments) => `"${options.property}"  ${validationMessage} даних про нотифікацію`
};
