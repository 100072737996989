import { FC, useCallback } from 'react';
import classNames from 'classnames';

import { UserInfo } from './user.info';
import { PositionsList } from './positions.list';
import { LinksList } from './links.list';

import { AsideNavProps } from './types';

import './aside.nav.scss';
import { useHistory } from 'react-router-dom';

export const AsideNav: FC<AsideNavProps> = ({ closeAside, statusAside }) => {
	const {
		location: { pathname },
		push
	} = useHistory();

	const onSelectHandler = useCallback(() => {
		pathname !== '/' && push('/');

		closeAside();
	}, [pathname, push, closeAside]);

	return (
		<section
			className={classNames('section-aside-nav', {
				active: statusAside
			})}
		>
			<div className="box-aside-nav">
				<button type="button" onClick={closeAside} className="btn-toggle-aside-nav">
					<span></span>
					<span></span>
				</button>
				<div className="aside-nav-content column">
					<div className="aside-head-content">
						<UserInfo />
						<PositionsList onPositionSelect={onSelectHandler} />
					</div>
					{statusAside ? (
						<div className="aside-main-content">
							<LinksList onLinkSelect={onSelectHandler} />
						</div>
					) : (
						''
					)}
				</div>
			</div>
		</section>
	);
};
