import { forwardRef, useEffect, useState } from 'react';

import { Widgets } from '../../components/widgets';

import { useAppDispatch, useAppState } from '../../context/store';
import { setRefreshIframe } from '../../context/actions';

import { iframeSrc } from '../../utils/helpers';

import { MainScreenProps } from './types';
import { Module } from '../../types/api.dto';

import './main.screen.scss';

export const MainScreen = forwardRef<HTMLIFrameElement, MainScreenProps>(({ onIframeRefresh }, ref) => {
	const {
		modules: { list: modules },
		activeData: { moduleCoords },
		iFrameParams
	} = useAppState();

	const dispatch = useAppDispatch();

	const [refresh, toggleRefresh] = useState<boolean>(false);

	useEffect(() => {
		dispatch(setRefreshIframe(() => toggleRefresh((refresh) => !refresh)));
	}, [dispatch]);

	useEffect(() => {
		onIframeRefresh && onIframeRefresh();
	}, [refresh, onIframeRefresh]);

	return (
		<section>
			<div className="box-main-screen box-in-full-screen" style={{ display: moduleCoords ? 'block' : 'none' }}>
				<iframe
					key={+refresh}
					id="childFrame"
					frameBorder="0"
					allow="true"
					className="box-iframe"
					src={
						moduleCoords
							? iframeSrc(
									modules.find((item) => item.coords.join('') === moduleCoords.join('')) as Module,
									iFrameParams
							  )
							: undefined
					}
					title="mainFrame"
					ref={ref}
				/>
			</div>
			<div className="container" style={{ display: !moduleCoords ? 'block' : 'none' }}>
				<Widgets />
			</div>
		</section>
	);
});
