import { FC, Fragment, useCallback, useEffect } from 'react';
import { useAnalytics } from '@temabit/perevershnyk-uikit/dist/Hooks';
import { formattedDateTime } from '@temabit/perevershnyk-uikit/dist/utils/format.date';

import { NotificationMsg } from '../../components/notification.msg';
import { NotificationCenterLoader } from '../../components/notification.center.loader';

import { useOpenNotification } from '../../hooks/use.open.notification';
import { useCheckMessage } from '../../hooks/url.hooks';

import { NotificationCenterProps } from './types';
import { NotificationCenterLoaderProps } from '../../components/notification.center.loader/types';
import { UserMessageDto } from '../../types/notifications.api.dto';

import './notification.center.scss';

export const NotificationCenter: FC<NotificationCenterProps> = ({
	isAllLoaded,
	messages,
	onCheckNotification,
	onScrollToBottom
}) => {
	const { pageViewGa, toggleEventGa } = useAnalytics();

	const { errorPopup, openNotification } = useOpenNotification();
	const checkNotification = useCheckMessage();

	const getDateTime = useCallback((date: Date) => {
		const dateParts = formattedDateTime(date, 'DD.MM.YY', 'HH:MM');

		return `${dateParts[0]} ${dateParts[1]}`;
	}, []);

	const getLoaderProps = useCallback(() => {
		return { isAllLoaded, onScrollToBottom } as NotificationCenterLoaderProps;
	}, [isAllLoaded, onScrollToBottom]);

	const openMessageHandler = useCallback(
		async (message: UserMessageDto, pageNumber: number, arrKey: number) => {
			const { id, data, body, title } = messages[pageNumber][arrKey];

			if (!message.checked) {
				const checkResponse = await checkNotification(id);
				onCheckNotification(message.id, pageNumber, arrKey, checkResponse);
			}

			openNotification({ messageId: id, options: data, body, title });
		},
		[messages, openNotification, checkNotification, onCheckNotification]
	);

	const handleOpenMsg = useCallback(
		async (msg: UserMessageDto, pageNumber: number, arrKey: number) => {
			toggleEventGa('Notification center', 'open-notification_button', 'open selected notification');

			await openMessageHandler(msg, pageNumber, arrKey);
		},
		[toggleEventGa, openMessageHandler]
	);

	useEffect(() => {
		pageViewGa('Notification center');
	}, [pageViewGa]);

	return (
		<section className="notification-center">
			<div className="container">
				<div className="box-module-screen box-in-full-screen box-content">
					<ul className="messages-list">
						{messages.map((page, pageNumber) => (
							<Fragment key={pageNumber}>
								{page.map((item, i) => (
									<NotificationMsg
										key={i}
										checked={item.checked}
										dateTime={getDateTime(item.created)}
										onOpenMsg={() => handleOpenMsg(item, pageNumber, i)}
										title={item.title}
										body={item.body}
									/>
								))}
							</Fragment>
						))}
						<NotificationCenterLoader {...getLoaderProps()} />
					</ul>
				</div>
			</div>
			{errorPopup}
		</section>
	);
};
