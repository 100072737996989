import { FC } from 'react';

import { NotificationBtn } from './index';

import { useAppState } from '../../context/store';
export const NotificationBtnProvider: FC = () => {
	const { uncheckedMessages } = useAppState();

	return <NotificationBtn hasUnread={uncheckedMessages} />;
};
