import { plainToClass } from 'class-transformer';
import { validateSync } from 'class-validator';
import { isMobile } from '@temabit/perevershnyk-rpc-shell/dist/mobile/helpers';
import { HttpClient } from '@temabit/perevershnyk-rpc-shell/dist/http/http.client';

import { getApiUrl } from '../../types/api.dto';

import type { ClassConstructor } from 'class-transformer';
import type { MobileWindow } from '@temabit/perevershnyk-rpc-shell';
import type { MyResponse } from '../../types/api.dto';

const getFirstError = (error: any): any => {
	const [firstError] = error;
	if (firstError.children.length > 0) {
		return getFirstError(firstError.children);
	} else {
		return Object.values(firstError.constraints);
	}
};

const loopInstantiatedArr = <Dto>(data: Dto[], instance: Dto) => {
	data.forEach(() => {
		const errors = validateSync(instance as unknown as Record<string, unknown>);
		if (errors.length) {
			console.log(errors);
			const error = getFirstError(errors);
			throw new Error(error[0].toString());
		}
	});
};

const cacheSet = async (key: string, value: string) => {
	if (!isMobile()) {
		return;
	}

	const curValue = await (window as unknown as MobileWindow).$RPC.request('cache:get', { [key]: '' });

	if (curValue[key] !== value) {
		await (window as unknown as MobileWindow).$RPC.request('cache:save', { [key]: value });
	}
};

export const instanceDtoValidate = <Dto>(dto: ClassConstructor<Dto>, response: Dto) => {
	const instance = plainToClass(dto, response);

	const data = instance instanceof Array ? (instance as Dto[]) : [instance];

	loopInstantiatedArr(data, instance);

	console.log(instance);

	return instance;
};

export const instanceClsValidate = <Cls extends ClassConstructor<MyResponse<any>>>(cls: Cls, response: any) => {
	const instance = plainToClass(cls, response);

	const data = instance.data instanceof Array ? instance.data : [instance.data];

	loopInstantiatedArr(data, instance);

	console.log(instance.data);

	return instance.data;
};

export const getData = async <Cls extends ClassConstructor<MyResponse<any>>>(
	cls: Cls,
	client: HttpClient,
	headers?: any,
	paramsPartStr?: string
): Promise<Cls extends ClassConstructor<MyResponse<infer R>> ? R : unknown> => {
	const url = getApiUrl(cls);

	const response = await client.get(`${url}${paramsPartStr || ''}`, { headers: { ...headers } });
	console.log(response);

	if (url.indexOf('modules') !== -1) {
		cacheSet('guid', response.headers._globaluserid);
	}

	const instance = instanceClsValidate(cls, response);
	console.log(instance);

	return instance;
};
