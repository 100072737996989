import { Plug } from '@temabit/perevershnyk-uikit/dist/Components';
import { useAnalytics } from '@temabit/perevershnyk-uikit/dist/Hooks';

import { FC, useCallback } from 'react';
import { useAppState } from '../../context/store';

import './critical.error.scss';

const supportBtnTitle = 'Написати у підтримку';

export const CriticalError: FC = ({ children = process.env.REACT_APP_RECEIVE_DATA_ERROR }) => {
	const { rpc } = useAppState();

	const { toggleEventGa } = useAnalytics();

	const nativeMailOpen = useCallback(async () => {
		await rpc.request('mail:open', {});
	}, [rpc]);

	const supportBtnClickHandler = useCallback(() => {
		toggleEventGa('Help', 'open-help_critical-error', 'open help section from critical error screen');

		nativeMailOpen();
	}, [toggleEventGa, nativeMailOpen]);

	return (
		<div className="App App-error">
			<main>
				<section>
					<div className="container">
						<div className="column box-module-screen box-plug box-plug-critical-error box-in-full-screen">
							<Plug type="error">{children || process.env.REACT_APP_RECEIVE_DATA_ERROR}</Plug>
							<button className="btn-support" onClick={supportBtnClickHandler}>
								<span className="btn-title">
									{supportBtnTitle}
								</span>
							</button>
						</div>
					</div>
				</section>
			</main>
		</div>
	);
};
