import { useCallback, useMemo } from 'react';
import { Birthdays as BirthdaysWidget } from '@temabit/perevershnyk-uikit/dist/Widgets';
import { formattedDate, newDate } from '@temabit/perevershnyk-uikit/dist/utils/format.date';

import { Widget } from '../widget';

import { useClsSWR } from '../../../hooks/url.hooks';
import { BirthdaysResponse } from '../../../utils/widgets.api';

import type { FC } from 'react';
import type { DateDependWidgetProviderProps } from '../types';

import { headers } from '../../../index';

const emptyBirthdaysList = 'У ваших колег нема днів народжень найближчі дні';

export const BirthdaysWidgetProvider: FC<DateDependWidgetProviderProps> = ({
	date,
	moduleBtnTitle,
	moduleCoords,
	title
}) => {
	const getParamUrlPart = useCallback(() => {
		const startDate = new Date(date);
		const endDate = newDate(startDate, 0, 0, 10);

		const formattedDateRange = [startDate, endDate].map((date) => formattedDate(date, 'DD.MM.YY'));

		return `${formattedDateRange.join('/')}/true`;
	}, [date]);

	const {
		data: birthdaysData,
		isLoading: isBirthdaysLoading,
		error: birthdaysError,
		revalidate: revalidateBirthdays
	} = useClsSWR(BirthdaysResponse, headers.positions, getParamUrlPart());

	const revalidateFnMemo = useMemo(() => {
		return birthdaysError ? revalidateBirthdays : null;
	}, [birthdaysError, revalidateBirthdays]);

	return (
		<Widget
			moduleBtnTitle={moduleBtnTitle}
			moduleCoords={moduleCoords}
			empty={{
				info: emptyBirthdaysList,
				buttonVisible: false
			}}
			isEmpty={!birthdaysData?.length}
			isError={!!birthdaysError}
			isLoading={isBirthdaysLoading}
			additionalParams={[
				{
					key: 'mode',
					value: 'month',
					requiredOperationGroups: ['perevershnyk_birthdays']
				}
			]}
			retryFn={revalidateFnMemo}
			title={title}
		>
			<BirthdaysWidget data={birthdaysData?.slice(0, 3) || null} />
		</Widget>
	);
};
