import { Fragment } from 'react';

import { ParentListItem } from './parent.list.item';
import { ChildListItem } from './child.list.item';

import { useCompareCoords } from './use.compare.coords';

import type { FC } from 'react';
import type { TreeListProps, ListItemType } from './types';

import './tree.list.scss';

export const TreeList: FC<TreeListProps> = ({
	list = [] as ListItemType[],
	activeItemCoords = null,
	onChangeActiveItem,
	onClickActiveItem,
	readonlyMode = false
}) => {
	const compareCoords = useCompareCoords(activeItemCoords);

	return (
		<ul className="tree-list">
			{list.map((item, i) => (
				<Fragment key={i}>
					{item.childNodes?.length ? (
						<ParentListItem
							parentItem={item}
							activeItemCoords={activeItemCoords}
							onActiveChildItemSelect={(itemId) => {
								onClickActiveItem && onClickActiveItem(itemId);
							}}
							onChildItemSelect={(itemId) => {
								onChangeActiveItem && onChangeActiveItem(itemId);
							}}
							readonlyMode={readonlyMode}
						/>
					) : (
						<ChildListItem
							idString={item.idString}
							isActive={!readonlyMode ? compareCoords(item.coords) : false}
							listItem={item}
							onActiveItemSelect={(itemId) => {
								onClickActiveItem && onClickActiveItem(itemId);
							}}
							onItemSelect={(itemId) => {
								onChangeActiveItem && onChangeActiveItem(itemId);
							}}
						/>
					)}
				</Fragment>
			))}
		</ul>
	);
};
