import { FC, Fragment, useCallback } from 'react';

import { ChildListItem } from '../child.list.item';

import { useCompareCoords } from '../use.compare.coords';

import { ParentListItemProps } from './types';
import { ListItemType } from '../types';

import iconToggleSubList from '../../../../../assets/images/icons/angel-right.svg';

import './parent.list.item.scss';

export const ParentListItem: FC<ParentListItemProps> = ({
	parentItem = {} as ListItemType,
	activeItemCoords = null,
	onActiveChildItemSelect,
	onChildItemSelect,
	readonlyMode = false
}) => {
	const compareCoords = useCompareCoords(activeItemCoords);

	const isLevelActive = useCallback(() => {
		if (parentItem.coords && activeItemCoords) {
			return activeItemCoords.join('').startsWith(parentItem.coords.join(''));
		} else {
			return false;
		}
	}, [activeItemCoords, parentItem.coords]);

	return (
		<li className="parent-list-item">
			<input
				className="list-checkbox"
				type="checkbox"
				name="tree-list-parents"
				id={`parent-${parentItem.coords?.join('')}`}
				defaultChecked={!readonlyMode ? isLevelActive() : false}
			/>
			<label className="btn list-item-label" htmlFor={`parent-${parentItem.coords?.join('')}`}>
				<span className="list-item-title">{parentItem.title}</span>
				<img className="arrow" src={iconToggleSubList} alt="" />
			</label>
			<ul className="child-list">
				{parentItem.childNodes?.map((item, i) => (
					<Fragment key={i}>
						{item.childNodes?.length ? (
							<ParentListItem
								activeItemCoords={activeItemCoords}
								parentItem={item}
								onActiveChildItemSelect={(itemCoords) => {
									onActiveChildItemSelect && onActiveChildItemSelect(itemCoords);
								}}
								onChildItemSelect={(itemCoords) => {
									onChildItemSelect && onChildItemSelect(itemCoords);
								}}
								readonlyMode={readonlyMode}
							/>
						) : (
							<ChildListItem
								idString={item.idString}
								isActive={!readonlyMode ? compareCoords(item.coords) : false}
								listItem={item}
								onActiveItemSelect={(itemId) => {
									onActiveChildItemSelect && onActiveChildItemSelect(itemId);
								}}
								onItemSelect={(itemId) => {
									onChildItemSelect && onChildItemSelect(itemId);
								}}
							/>
						)}
					</Fragment>
				))}
			</ul>
		</li>
	);
};
