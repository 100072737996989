import { FC } from 'react';

import './main.plug.scss';

import PlugAppImg from '../../assets/images/plug-app.svg';

const greetingsTitle = 'вітаю у додатку';

export const MainPlug: FC = () => {
	return (
		<div className="column box-module-screen box-plug box-in-full-screen">
			<img className="plug-img" src={PlugAppImg} alt="" />
			<div className="title title-plug-app">{greetingsTitle}!</div>
		</div>
	);
};
