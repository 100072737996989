import { StrictMode, useEffect } from 'react';
import { render } from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';

import './polyfill';
import 'reflect-metadata';

import { isMobile } from '@temabit/perevershnyk-rpc-shell/dist/mobile/helpers';
import { Payload } from '@temabit/perevershnyk-uikit/dist/Components';
import { useAnalytics } from '@temabit/perevershnyk-uikit/dist/Hooks';

import { AppDesktop } from './App.desktop';
import { AppMobile } from './App.mobile';

import { RpcProvider } from './hoc/rpc.provider';

import { AppProvider, useAppState } from './context/store';

import './assets/scss/index.scss';

const { REACT_APP_MODULES_HEADERS, REACT_APP_POSITIONS_HEADERS } = process.env;

export const headers = {
	modules: REACT_APP_MODULES_HEADERS ? JSON.parse(REACT_APP_MODULES_HEADERS) : REACT_APP_MODULES_HEADERS,
	positions: REACT_APP_POSITIONS_HEADERS ? JSON.parse(REACT_APP_POSITIONS_HEADERS) : REACT_APP_POSITIONS_HEADERS
};

function App() {
	const { initAnalytics } = useAnalytics();

	useEffect(() => {
		initAnalytics();
	}, [initAnalytics]);

	return isMobile() ? (
		<RpcProvider>
			<AppMobile />
		</RpcProvider>
	) : (
		<AppDesktop />
	);
}

function InitClient() {
	const { showLoading } = useAppState();

	return (
		<Router>
			<App />
			{showLoading ? <Payload /> : ''}
		</Router>
	);
}

render(
	<StrictMode>
		<AppProvider>
			<InitClient />
		</AppProvider>
	</StrictMode>,
	document.getElementById('root')
);
