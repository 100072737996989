import {
	Schedule as ScheduleConfig,
	News as NewsConfig,
	Birthdays as BirthdaysConfig
} from '@temabit/perevershnyk-uikit/dist/Configs';

import { baseApiUrl } from './helpers';

import { createResponseClass } from '../types/api.dto';

const {
	REACT_APP_API_URL_SCHEDULE: apiSchedule,
	REACT_APP_API_URL_NEWS: apiNews,
	REACT_APP_API_URL_BIRTHDAYS: apiBirthdays
} = process.env;

export const ScheduleAPI = {
	PersonalInfoResponse: createResponseClass(
		ScheduleConfig.PersonalInfo.WrappedPersonalInfoDto,
		`${baseApiUrl('perevershnyk_schedule', apiSchedule)}${ScheduleConfig.PersonalInfo.PersonalInfoUrl}`
	),
	LineResponse: createResponseClass(
		ScheduleConfig.Line.WrappedLineDto,
		`${baseApiUrl('perevershnyk_schedule', apiSchedule)}${ScheduleConfig.Line.LineUrl}`
	),
	AdminResponse: createResponseClass(
		ScheduleConfig.Admin.WrappedAdminDto,
		`${baseApiUrl('perevershnyk_schedule', apiSchedule)}${ScheduleConfig.Admin.AdminUrl}`
	)
};

export const NewsResponse = createResponseClass(
	NewsConfig.NewsItemDto,
	`${baseApiUrl('perevershnyk_spbridge', apiNews)}${NewsConfig.NewsUrl}`,
	true
);

export const BirthdaysResponse = createResponseClass(
	BirthdaysConfig.UserProfileDto,
	`${baseApiUrl('perevershnyk_spbridge', apiBirthdays)}${BirthdaysConfig.BirthdaysUrl}`,
	true
);
