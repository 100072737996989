import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from '@temabit/perevershnyk-uikit/dist/Hooks';

import { TreeList } from './tree.list';

import { useAppState, useAppDispatch } from '../../../context/store';
import { resetIFrameParams, setActiveDataId } from '../../../context/actions';

import { isAbsoluteURL } from '../../../utils/helpers';

import type { FC } from 'react';
import type { LinksListProps } from './types';
import type { Module } from '../../../types/api.dto';

import './links.list.scss';

const btnTitles = {
	home: 'головна сторінка',
	service: 'підтримка'
};

export const LinksList: FC<LinksListProps> = ({ onLinkSelect }) => {
	const {
		rpc,
		modules: { tree: modulesTree, list: modulesList },
		activeData: { moduleCoords },
		refreshIframe
	} = useAppState();
	const dispatch = useAppDispatch();

	const { toggleEventGa } = useAnalytics();

	const { pathname } = useLocation();

	const nativeMailOpen = useCallback(async () => {
		await rpc.request('mail:open', {});
	}, [rpc]);

	const homeChangeActiveHandler = useCallback(() => {
		dispatch(setActiveDataId('moduleCoords', null));
		onLinkSelect();
	}, [onLinkSelect, dispatch]);

	const homeClickActiveHandler = useCallback(() => {
		onLinkSelect();

		window.location.reload();
	}, [onLinkSelect]);

	const listItemChangeActiveHandler = useCallback(
		(itemCoords: number[]) => {
			const module = modulesList.find((item) => item.coords.join('') === itemCoords.join('')) as Module;

			if (isAbsoluteURL(module.url as string)) {
				toggleEventGa('Outer link', 'open-outer_link_list-item', `navigate to ${module.url} from main menu`);
				rpc.request('browserModule:open', { url: module.url });
			} else {
				dispatch(setActiveDataId('moduleCoords', itemCoords));
			}

			onLinkSelect();
		},
		[modulesList, rpc, onLinkSelect, toggleEventGa, dispatch]
	);

	const listItemClickActiveHandler = useCallback(() => {
		refreshIframe && refreshIframe();
		dispatch(resetIFrameParams());
		onLinkSelect();
	}, [onLinkSelect, refreshIframe, dispatch]);

	const helpChangeActiveHandler = useCallback(() => {
		toggleEventGa('Help', 'open-help_list-item', 'open help section from main menu');

		nativeMailOpen();
		onLinkSelect();
	}, [toggleEventGa, onLinkSelect, nativeMailOpen]);

	return (
		<nav className="links-list">
			<TreeList
				activeItemCoords={moduleCoords}
				list={[{ title: btnTitles.home, coords: null, idString: 'main' }]}
				onChangeActiveItem={homeChangeActiveHandler}
				onClickActiveItem={homeClickActiveHandler}
				readonlyMode={pathname !== '/'}
			/>
			<TreeList
				list={modulesTree}
				activeItemCoords={moduleCoords}
				onChangeActiveItem={listItemChangeActiveHandler}
				onClickActiveItem={listItemClickActiveHandler}
			/>
			<div className="service-wrapper">
				<TreeList
					list={[{ title: btnTitles.service, idString: 'help' }]}
					onChangeActiveItem={helpChangeActiveHandler}
				/>
			</div>
		</nav>
	);
};
