import { useCallback } from 'react';
import { TreeListProps, ListItemType } from './types';

export const arrayToStr = (arr: any) => (arr instanceof Array ? arr : [arr]).join('');

export const useCompareCoords = (activeItemCoords: TreeListProps['activeItemCoords']) => {
	const compareCoords = useCallback(
		(itemCoords: ListItemType['coords']) => {
			if (typeof itemCoords === 'undefined') {
				return false;
			}

			const activeCoordsStr = arrayToStr(activeItemCoords);
			const itemCoordsStr = arrayToStr(itemCoords);

			return activeCoordsStr === itemCoordsStr;
		},
		[activeItemCoords]
	);

	return compareCoords;
};
