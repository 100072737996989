import { Exclude, Expose, plainToClass, Transform, Type } from 'class-transformer';
import {
	IsArray,
	IsBoolean,
	IsDate,
	IsInt,
	IsString,
	Min,
	MinLength,
	ValidateIf,
	ValidateNested
} from 'class-validator';
import { toDate } from '@temabit/perevershnyk-uikit/dist/utils/transform.utils';

import { UserMessageValidationLogger } from './validation.errors';

import type { ClassConstructor } from 'class-transformer';

const JSONToClass = <T>(value: string, cls: ClassConstructor<T>) => {
	if (!value) {
		return null;
	}

	if (typeof value !== 'string') {
		return value;
	}

	try {
		return plainToClass(cls, JSON.parse(value));
	} catch (error) {
		console.log('Parsing error occurred', value);
		console.dir(error);

		return null;
	}
};

@Exclude()
export class OptionsParameter {
	@Expose()
	@IsString()
	@MinLength(1)
	public key: string;

	@Expose()
	@IsString()
	@MinLength(1)
	public value: string;
}

@Exclude()
export class NotificationDataOptions {
	@Expose()
	@IsString()
	@MinLength(1)
	public operationName: string;

	@Expose()
	@IsArray()
	@Type(() => OptionsParameter)
	@ValidateNested({ each: true })
	public parameters: OptionsParameter[];
}

@Exclude()
export class NotificationDataDto {
	@Expose()
	@Transform(({ value }) => +value)
	@IsInt()
	@Min(0)
	public messageId: number;

	@Expose()
	@Type(() => NotificationDataOptions)
	@Transform(({ value }) => JSONToClass(value, NotificationDataOptions))
	@ValidateIf((obj, val) => val)
	@ValidateNested()
	public options: NotificationDataOptions | null;

	@Expose()
	@IsString()
	public title: string;

	@Expose()
	@IsString()
	public body: string;
}

@Exclude()
export class UncheckedMessagesDto {
	@Expose()
	@IsInt({ ...UserMessageValidationLogger })
	@Min(0, { ...UserMessageValidationLogger })
	public count: number;
}

@Exclude()
export class UserMessageDto {
	@Expose()
	@IsInt({ ...UserMessageValidationLogger })
	@Min(0, { ...UserMessageValidationLogger })
	public id: number;

	@Expose()
	@IsString({ ...UserMessageValidationLogger })
	@MinLength(1, { ...UserMessageValidationLogger })
	public login: string;

	@Expose()
	@IsString({ ...UserMessageValidationLogger })
	@MinLength(1, { ...UserMessageValidationLogger })
	public title: string;

	@Expose()
	@IsString({ ...UserMessageValidationLogger })
	@MinLength(1, { ...UserMessageValidationLogger })
	public body: string;

	@Expose()
	@Type(() => NotificationDataOptions)
	// @Transform(({ value }) => JSONToClass(value, NotificationDataOptions))
	@ValidateIf((obj, val) => val)
	// @ValidateNested({ each: true, ...UserMessageValidationLogger })
	public data: NotificationDataOptions | null;

	@Expose({ name: 'created_at' })
	@IsDate()
	@Transform(({ value }) => toDate(value))
	public created: Date;

	@Expose()
	@IsBoolean({ ...UserMessageValidationLogger })
	public checked: boolean;
}

@Exclude()
export class CheckMessageDto {
	@Expose()
	@IsBoolean({ ...UserMessageValidationLogger })
	public success: boolean;

	@Expose()
	@IsInt({ ...UserMessageValidationLogger })
	@Min(0, { ...UserMessageValidationLogger })
	public unchecked: number;
}
