export enum AppActionTypes {
	SetModules = 'SET_MODULES',
	SetPositions = 'SET_POSITIONS',
	SetFio = 'SET_FIO',
	SetActiveDataId = 'SET_ACTIVE_DATA_ID',
	SetUncheckedMessages = 'SET_UNCHECKED_MESSAGES',
	SetIFrameParams = 'SET_IFRAME_PARAMS',
	ResetIFrameParams = 'RESET_IFRAME_PARAMS',
	SetShowLoading = 'SET_SHOW_LOADING',
	SetRefreshIframe = 'SET_REFRESH_IFRAME'
}
