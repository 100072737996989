import { FC, useCallback } from 'react';
import classNames from 'classnames';

import { NotificationMsgProps } from './types';

import './notification.msg.scss';

import ArrowIcon from '../../assets/images/icons/angel-right-orange.svg';

export const NotificationMsg: FC<NotificationMsgProps> = ({ body, checked, dateTime, onOpenMsg, title }) => {
	const getMsgBtnClasses = useCallback(() => {
		return classNames('msg-btn', { unchecked: !checked });
	}, [checked]);

	return (
		<li className="msgs-item">
			<div className={getMsgBtnClasses()} onClick={onOpenMsg}>
				<div className="msg-content">
					<div className="date-time">{dateTime}</div>
					<div className="msg-title">{title}</div>
					<div className="msg-body">{body}</div>
				</div>
				<div className="open-arrow">
					<img className="img-arrow" src={ArrowIcon} alt="" />
				</div>
			</div>
		</li>
	);
};
